<template>
  <div>
    <div class="">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("initialize");
  },
};
</script>

<style>
</style>
