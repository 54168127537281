import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import Maska from 'maska'
import vSelect from 'vue-select'

import router from './router/'
import mainStore from './store'
import { createStore } from 'vuex'
import Toast from "vue-toastification";
import { useToast } from "vue-toastification";

import "vue-toastification/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/style.css";
import "./assets/auxiliary/fontawesome/css/all.css";

const toastOptions = {};

const app = createApp(App)
app.use(router);
app.use(Maska);
app.component('v-select', vSelect);



app.config.globalProperties.$http = axios;
app.config.globalProperties.$_http = axios;

app.use(Toast, toastOptions);

const store = createStore(mainStore);
app.use(store)

app.config.globalProperties.$message = function (tipo, mensagem) {
    const toast = useToast();
    toast(mensagem, { type: tipo });
}


app.config.globalProperties.$error = function (error) {
    let errors = (error.response) ? error.response.data : error;

    if (typeof errors === 'object') {
        let html = '<ul>';

        $.each(errors, function (key, value) {
            html += '<li>' + value[0] + '</li>';
        });

        html += '</ul>';

        errors = html;
    }

    const toast = useToast();
    toast(errors, { type: 'error' });
};
app.mount('#app')