import env from '../config/loadEnv';
import router from '../router/index'
import axios from 'axios'

const baseURL = env.baseURL;
const normalURL = env.normalURL;

const store = {
    state: {
        user: {
            id: 0,
            name: '',
            photo: '',
            email: ''
        },
        company: {
            id: 0,
            name: '',
            logo_url: '',
            banner_url: ''
        },
        authenticated: false,
        token: '',
        api: baseURL,
        url: normalURL,
    },
    getters: {
        token: state => {
            return state.token;
        },
        authenticated: state => {
            if (state.token == null || state.token == 'null') {
                return false;
            }

            return state.authenticated;
        },
        user: state => {
            return state.user;
        },
        company: state => {
            return state.company;
        },
        api: state => {
            return state.api;
        },
        url: state => {
            return state.url;
        },
    },
    mutations: {
        auth(state, token) {
            const self = this;

            if (token != null && token != undefined) {
                state.authenticated = true;
                state.token = token;

                localStorage.clear();
                localStorage.setItem('token', token);

                axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');

                this.commit('profileUpdate');
            }
        },
        profileUpdate(state) {

            axios.post(state.api + 'me-candidate', {
                token: state.token
            })
                .then(response => {
                    state.user = response.data
                    state.authenticated = true;
                })
                .catch(error => {

                    state.authenticated = false;
                    state.token = '';
                    localStorage.clear();

                    router.push('/');
                });
        },
        company(state, company) {
            const self = this;

            if(state.company.id == 0 && company.company_id !== state.company.id && company.company_id !== null) {
                state.company.id = company.company_id;
                state.company.name = company.company_name
                state.company.logo_url = company.company_info.logo;
                state.company.banner_url = company.company_info.main_banner;

                localStorage.setItem('company_id', company.company_id);
                localStorage.setItem('company_name', company.company_name);
                localStorage.setItem('logo_url', company.company_info.logo);
                localStorage.setItem('banner_url', company.company_info.main_banner);
            }
        },
        logout(state) {
            state.authenticated = false
        }

    },
    actions: {
        initialize(context) {
            const token = localStorage.getItem('token');
            context.commit('auth', token);
        }
    }
};

export default store;