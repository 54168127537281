import { createRouter, createWebHistory } from 'vue-router'

import Home from './home'

const routes = [
    Home
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router